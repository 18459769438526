import React from 'react';
import './Header.css';
// import Logo from'../images/logo.png';

const Header = () => (
	<div className="Header">
		<div className="HeaderGroup">
			<a href="/">Home</a>
			<a href="/projects">My Projects</a>
			<a className={'btn1'} href="/resume.pdf">Resume</a>
		</div>
		<div className="HeaderGroupResponsive">
			<a>☰</a>
		</div>
	</div>
);

export default Header;
