var slug2post = {
    "collegeboard": {
        markdownFile: 'collegeboard.md',
        title: 'College Board',
        link: 'http://miralomacollegeboard.herokuapp.com/',
        subtitle: "A board listing college acceptance for MLHS",
        image: "cb.png"
    },
    cartz: {
        markdownFile: 'cartz.md',
        title: 'Cartz',
        link: 'https://cartz.webflow.io/',
        subtitle: "A communal delivery system",
        image: "cartz.png"
    },
    boxhead3D: {
        markdownFile: 'boxhead.md',
        title: 'Boxhead 3D',
        link: 'https://github.com/ahmadhuzaifa/boxhead3D',
        subtitle: "A first person shooting game",
        image: "boxhead.png"

    },
    covidx: {
        markdownFile: 'covidx.md',
        title: 'COVIDx by CitizenHealth',
        link: 'https://covidx.app/',
        subtitle: "A COVID-19 disease tracker",
        image: "covid tracker.png"
    },
    skinskan: {
        markdownFile: 'skinskan.md',
        title: 'SkinSkan',
        link: 'https://github.com/ahmadhuzaifa/skinskan',
        subtitle: "A skin cancer detection application",
        image: "skinskan.jpg"
    },
    datalabel: {
        markdownFile: 'datalabel.md',
        title: 'Data Labelling',
        link: 'https://data-labelling.herokuapp.com/',
        subtitle: "A data labeling system",
        image: "data-label.jpg"
    },
    "instagram-scraper": {
        markdownFile: 'instagram-scraper.md',
        title: 'Instagram Scrapper',
        link: 'https://github.com/ahmadhuzaifa/Instagram-scraper',
        subtitle: "An instragram scrapper",
        image: "instagram-scraper.png"

    },
    "halal": {
        markdownFile: 'halal.md',
        title: 'Halal Food',
        link: 'https://drive.google.com/file/d/19VupbFx9-Q8lXDu_EIeuYIaAjMTg14k9/view',
        subtitle: "A halal food application",
        image: "halal.jpeg"
    },
    "gosearch": {
        markdownFile: 'gosearch.md',
        title: 'Go Search',
        link: 'https://github.com/ahmadhuzaifa/gosearch',
        subtitle: "A search engine powered by instagram posts",
        image: "search.png"
    },
    // usapt:{
    //     markdownFile: 'usapt.md',
    //     title: 'USAPT',
    //     link: 'www.USAPT.us',
    //     subtitle: "Scraped congress bills",
    //     image:"cartz.png"
    // },

};

export default slug2post;