import React, { Component } from 'react';
import { BrowserRouter, Route, Switch } from "react-router-dom";

import Home from './routes/home';
import Project from './routes/project';
import Projects from './routes/projects';
import Wander from './routes/applications/wander';
import CoastPay from './routes/applications/coastpay';
import alinea from './routes/applications/alinea';
import Around from './routes/applications/around';
import Coinbooks from './routes/applications/coinbooks';


class App extends Component {
  render() {
    return (
      <div className="App">
        <BrowserRouter>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/pages/projects/:projectName" component={Project} />
            <Route path="/projects/:projectName" component={Project} />
            <Route path="/projects" component={Projects} />
            <Route path="/wander" component={Wander} />
            <Route path="/coastpay" component={CoastPay} />
            <Route path="/alinea" component={alinea} />
            <Route path="/around" component={Around} />
            <Route path="/coinbooks" component={Coinbooks} />

          </Switch>
        </BrowserRouter>
      </div>
    );
  }
}
export default App;
