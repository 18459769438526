import React, { Component } from 'react';
import Header from '../components/header';
import { Link } from 'react-router-dom';

import slug2post from '../posts/slug2post';

import Card from '../components/Card';

import '../style/template.css';

class Projects extends Component {
	constructor(props) {
		super(props);
	}
	componentDidMount() {
		// var src = fetch('../posts/test.md').then((r) => r.text()).then((text) => {
		// for(posts inn)
		console.log(Object.keys(slug2post));
	}

	render() {
		return (
			<div>
				<Header />

				<section id={'header'}>
					<div className={'sectionGroup'}>
						{/* <h1>Hi! This is</h1> */}
						<h1>My Projects</h1>
					</div>
				</section>
				<section id={'content'}>
					<div className={'sectionGroup'} id={'contentRoot'}>
						<div className="CardGroup">
							{Object.keys(slug2post).map(function(item, i) {
								return (
									<Card
										id={i}
										title={slug2post[item]['title']}
										link={'/projects/' + item}
										text={slug2post[item]['subtitle']}
										Image={require('../images/'+slug2post[item]['image'])}
									/>
								);
							})}
						</div>
					</div>
				</section>
			</div>
		);
	}
}

export default Projects;
