import React, { Component } from 'react';
import Header from '../components/header';
import { Link } from 'react-router-dom';

import slug2post from '../posts/slug2post';

import marked from 'marked';

import '../style/template.css';

class Project extends Component {
	constructor(props) {
		super(props);
		this.state = {
			markdownText: ''
		};
	}
	componentDidMount() {
		// var src = fetch('../posts/test.md').then((r) => r.text()).then((text) => {
		const projectName = this.props.match.params.projectName;
		if (slug2post[projectName]) {
			const projectObject = slug2post[projectName];
			this.setState({ projectObject: projectObject });
			const file = require(`../posts/` + projectObject['markdownFile']);

			fetch(file.default)
				.then((response) => {
					return response.text();
				})
				.then((text) => {
					this.setState({
						markdown: marked(text)
					});
				});
		} else {
			// this.location.replace('/')
		}
	}

	render() {
		const { markdown } = this.state;
		const { projectObject } = this.state;

		return (
			<div>
				<Header />

				<section id={'header'}>
					<div className={'sectionGroup'}>
						{/* <h1>Hi! This is</h1> */}
						{projectObject != undefined && <h1>{projectObject.title}</h1>}
						{projectObject != undefined && (
							// <Link to={projectObject.link}>
								<a className={'btn1 inverted'} href={projectObject.link}>Open Project</a>
							// </Link>
						)}
					</div>
				</section>
				<section id={'content'}>
					<div className={'sectionGroup'} id={'contentRoot'}>
						{/* <Markdown source={this.state.markdownText} /> */}
						<article dangerouslySetInnerHTML={{ __html: markdown }} />
					</div>
				</section>
			</div>
		);
	}
}

export default Project;
