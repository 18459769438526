import React, { Component } from 'react';
import Header from '../components/header';
import Footer from '../components/footer';
import ScrollAnimation from 'react-animate-on-scroll';
import AnimatedCursor from 'react-animated-cursor';

import Card from '../components/Card';

import 'animate.css/animate.min.css';
import '../style/template.css';

class Home extends Component {
	constructor(props) {
		super(props);
		this.state = {
			cursorXY: { x: 100, y: -100 }
		};
	}

	componentDidMount() {
		const moveCursor = (e) => {
			const x = e.clientX - 16;
			const y = e.clientY - 16;
			this.setState({ cursorXY: { x, y } });
		};

		window.addEventListener('mousemove', moveCursor);
		return () => {
			window.removeEventListener('mousemove', moveCursor);
		};
	}

	render() {
		return (
			<div>
				<Header />
				<AnimatedCursor
					innerSize={30}
					outerSize={30}
					color="54, 100, 232"
					outerAlpha={0.3}
					innerScale={0.7}
					outerScale={5}
					style={{
						'mix-blend-mode': 'difference'
					}}
				/>
				<section className={'intro'}>
					<div className={'sectionGroup'}>
						<ScrollAnimation animateIn="fadeInUp">
							<h2>Hi, my name is</h2>
						</ScrollAnimation>
						<div style={{ marginBottom: 30, marginTop: 10 }}>
							<ScrollAnimation animateIn="fadeInUp" delay={150}>
								<h1>Huzaifa <span className={'secondH1'}> Ahmad.</span> </h1>
							</ScrollAnimation>
							<ScrollAnimation animateIn="fadeInUp" delay={300}>
								<h1 id={'thirdH1'}>I build for the web.</h1>
							</ScrollAnimation>
						</div>

						<ScrollAnimation animateIn="fadeInUp" delay={450}>
							<div style={{ width: "60%" }}>
								<p>
									I am a student at the University of California, Berkeley studying Computer Science. I have experience with Full-Stack Development, Machine Learning,and Data Science.
								</p>
							</div>
						</ScrollAnimation>

						<ScrollAnimation animateIn='fadeInUp' delay={600}>

							<div className={'links'}>
								<a target="_blank" href={'https://www.linkedin.com/in/ahmadhuzaifa012/'}>
									LinkedIn
								</a>
								<a target="_blank" href={'https://github.com/ahmadhuzaifa'}>
									Github
								</a>
								<a target="_blank" href={'https://twitter.com/ahmadhuzaifa012'}>
									Twitter
								</a>
							</div>

						</ScrollAnimation>


					</div>
				</section >
				<section id={'about'}>
					<div className={'sectionTitle'}>
						<h1>About Me</h1>
					</div>
					<div className={'sectionGroup'} id={'aboutGrid'}>
						<div className={'aboutContainer'}>
							<h1>Founder, Engineer, Designer.</h1>
							<p>
								🏫 Student @ <b>University of California, Berkeley</b>
							</p>
							<p>
								🛠 FullStack Engineer @ <b>GoodWealth</b>
							</p>
							<p>
								💼 Former Machine Learning Intern @ <b>Wing Assistant</b>
							</p>
							<p>
								🔬 Former AI Research Intern @ <b>Craftnetics</b>
							</p>
							<p>
								👀  Looking for internships
							</p>



							<div className={'links'}>
								<a target="_blank" href={'https://www.linkedin.com/in/ahmadhuzaifa012/'}>
									LinkedIn
								</a>
								<a target="_blank" href={'https://github.com/ahmadhuzaifa'}>
									Github
								</a>
								<a target="_blank" href={'https://twitter.com/ahmadhuzaifa012'}>
									Twitter
								</a>
							</div>
						</div>
						{/* <div>
							<img
								id={'profilepic'}
								src={
									'https://media-exp1.licdn.com/dms/image/C5603AQEJQdIZjoi2ng/profile-displayphoto-shrink_400_400/0/1594072476321?e=1620864000&v=beta&t=OjdMSRLMKVKxrg76aOTY4gL25DDWB_3-b2azpj-Juis'
								}
							/>
						</div> */}
					</div>
				</section>
				<section id={'experience'} style={{ 'margin-top': '100px' }}>
					<div className={'sectionTitle'}>
						<h1>Experience</h1>
					</div>
					<div className={'sectionGroup'}>
						<div className={'timeline'}>
							<ul>
								<div className={'timelineEvent'}>
									<div className={'experienceDetail'}>
										<a href={'https://www.goodwealth.com'}><h1>GoodWealth</h1></a>
										<h2>Founding Engineer</h2>
										<p>January, 2021 - September, 2021</p>
									</div>
									<div className={'experienceImg'}>
										<img
											src={'https://res.cloudinary.com/crunchbase-production/image/upload/c_lpad,h_170,w_170,f_auto,b_white,q_auto:eco,dpr_1/ckewbzogfdahh6drtxss'}
										/>
									</div>
								</div>

								<div className={'timelineEvent'}>
									<div className={'experienceDetail'}>
										<a href={'https://www.wingassistant.com'}>
											<h1>Wing Assistant</h1>

										</a>
										<h2>Machine Learning Intern</h2>
										<p>March, 2020 - January 2021</p>
									</div>
									<div className={'experienceImg'}>
										<img
											src={
												'https://media-exp1.licdn.com/dms/image/C560BAQFzh6GaeE-2lw/company-logo_200_200/0/1598993330998?e=2159024400&v=beta&t=T0n7IRzyPSMNROMg4x7u4sL_EHXr8z1apyhUPsR6atc'
											}
										/>
									</div>
								</div>


								<div className={'timelineEvent'}>
									<div className={'experienceDetail'}>
										<h1>COVIDx by CitzenHealth</h1>
										<h2>Volunteer FullStack Engineer</h2>
										<p>April, 2020 - June, 2020</p>
									</div>
									<div className={'experienceImg'}>
										<img
											src={
												'https://citizenhealth.io/wp-content/uploads/2020/05/cropped-512x512-1-32x32.png'
											}
										/>
									</div>
								</div>

								<div className={'timelineEvent'}>
									<div className={'experienceDetail'}>
										<h1>Craftnetics AI</h1>
										<h2>AI Research Intern</h2>
										<p>May, 2019 - August, 2019</p>
									</div>
									<div className={'experienceImg'}>
										<img
											src={
												'https://zenprospect-production.s3.amazonaws.com/uploads/pictures/5ed76dbad0ecde00018bb21d/picture'
											}
										/>
									</div>
								</div>
							</ul>
						</div>
					</div>
				</section>

				<section id={'projects'} style={{ 'margin-top': '100px' }}>
					<div className={'sectionTitle'}>
						<h1>Projects</h1>
					</div>
					<div className={'sectionGroup'}>
						<div className="CardGroup">
							<Card
								title="Cartz"
								link="/projects/cartz"
								text="A communal delivery service"
								Image={require('../images/cartz.png')}
							/>

							<Card
								title="BoxHead 3D"
								link="/projects/boxhead3D"
								text="FirstPerson Shooting game"
								Image={require('../images/boxhead.png')}
							/>
							<Card
								title="SkinSkan"
								link="/projects/skinskan"
								text="A skin cancer detection application"
								Image={require('../images/skinskan.jpg')}
							/>
							<Card
								title="COVIDx"
								link="/projects/covidx"
								text="A COVID 19 tracker"
								Image={require('../images/covid tracker.png')}
							/>
							<Card
								title="Data Labeling"
								link="/projects/datalabel"
								text="FirstPerson Shooting game"
								Image={require('../images/data-label.jpg')}
							/>
							<Card
								title="Halal Food App"
								link="/projects/halal"
								text="A halal restaraunt finder"
								Image={require('../images/halal.jpeg')}
							/>
						</div>
						<a className={'btn2'} href={'/projects'}>
							See more projects
						</a>
					</div>
				</section>
				{/* <Footer /> */}
			</div >
		);
	}
}

export default Home;
