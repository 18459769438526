import React, { Component } from 'react';
import image from "../../images/bg.jpeg"
import goodwealth from "../../images/goodwealth.png"
import wing from "../../images/blue.jpeg"
import Craftnetics from "../../images/craftnetics.png"



import ScrollAnimation from 'react-animate-on-scroll';

import 'animate.css/animate.min.css';
import './applications.css';

import '../../style/template.css';

import Header from '../../components/header';
import Tag from '../../components/tags';
import Card from '../../components/Card';

class Coinbooks extends Component {

    render() {
        return (
            <div id={"wander"}>
                <Header />
                <section style={{
                    backgroundImage: `url(${image})`, backgroundSize: "cover", backgroundPosition: "top", height: "80vh",
                }}>
                    <div className={'sectionGroup'} >
                        <div style={{ width: "50%", maxWidth: 800 }}>
                            <ScrollAnimation animateIn="fadeInUp">
                                <h3>MY APPLICATION</h3>
                            </ScrollAnimation>
                            <div style={{ marginBottom: 30, marginTop: 10, }}>
                                <ScrollAnimation animateIn="fadeInUp" delay={150}>
                                    <h1 style={{ fontWeight: 900 }}> to Coinbooks.</h1>
                                </ScrollAnimation>
                            </div>

                            <ScrollAnimation animateIn="fadeInUp" delay={350}>
                                <div>
                                    <p>
                                        Hello there! 👋 My name's Huzaifa, and I am a student at UC Berkeley. I think what you guys are building is pretty cool.

                                        I wanted to showcase my experiences and how they relate to the Full Stack Engineering position at Coinbooks, so I decided to build this webpage.
                                    </p>
                                </div>
                            </ScrollAnimation>

                        </div>
                    </div>

                </section>
                <section id={'content'}>
                    <div className={'sectionGroup'} id={'contentRoot'}>
                        <div style={{ textAlign: "center" }}>
                            <h1>
                                My Story & Experiences
                            </h1>
                            <p>Or if you'd prefer a traditional resumé, find it <a href='../resume.pdf'>here.</a></p>
                        </div>
                        <p>
                            I consider myself to be a
                            <span id={"gradient"}> hacker</span> and a <span id={"gradient"}> builder</span>—someone that can take creative ideas and transform them into reality.
                            Here's a summary some of previous work:

                        </p>
                    </div>
                </section>
                <section style={{ background: `D2D2D2`, backgroundSize: "cover", height: "600px", }}>
                    <div className={'sectionGroup'} id={'contentRoot'}>
                        <div className={'expContainer'} style={{ display: "flex" }}>
                            <div className='expItem'>
                                <h1><span style={{ fontWeight: 300 }}> 1. Winning a </span>Global Hackathon</h1>
                                <p>It all started with a win at a win at the School of AI Global Health Hackathon. In 2018, we won $10,000 for creating a skin cancer detection application.</p>
                                <div className={'links'}>

                                    <a target="_blank" href={'https://github.com/ahmadhuzaifa/skinskan'}>
                                        Github Repo
                                    </a>
                                    <a target="_blank" href={'https://twitter.com/sirajraval/status/1122973844409503744?lang=en'}>
                                        Twitter Post
                                    </a>
                                </div>
                            </div>

                            <div className='expItem'>
                                <ul>
                                    <li>
                                        Our model used 28x28 RGB image training data mostly for faster training time and less variation in imaging.
                                    </li>

                                    <li>
                                        I was trained with 4 Outputs: Melanoma (Malignant/Benign) and Keratosis (Malignant/Benign).

                                    </li>

                                    <li>

                                        All the training data is from dermatoscope imaging. Thus, our project is meant for non-clinical use but for individuals who notice a lesion and would like a quick yet accurate diagnosis via dermatoscope attachment to smartphone either for personal use or for one-time use at local pharmacies such as a CVS.

                                    </li>
                                    <li>
                                        While the model can run on normal close-up smartphone images, lighting will be nonideal and the image may be blurry, providing less accurate results. There are other applications in smartphone app stores that focus on this more layman imaging.

                                    </li>
                                </ul>

                                <div style={{ display: "flex", flexDirection: "row", gap: 5, marginTop: 10 }}>
                                    <Tag name="Machine Learning" />
                                    <Tag name="Swift" />
                                    <Tag name="Tensorflow" />
                                </div>
                            </div>
                        </div>
                    </div>

                </section >
                <section style={{ backgroundImage: `url(${Craftnetics})`, backgroundSize: "cover", height: "600px", }}>
                    <div className={'sectionGroup'} id={'contentRoot'}>
                        <div className='expContainer'>
                            <div className='expItem'>
                                <h1><span style={{ fontWeight: 300 }}> 2. ML Reseach @  </span> Craftnetics</h1>
                                <p>After winning the hackathon one of the <a href='https://bankh.github.io/'>judges</a>, who then was a graduate student at CMU, offered me to research under him as an intern.</p>
                                <div className={'links'}>
                                    <a target="_blank" href={'https://craftnetics.ai/'}>
                                        Company
                                    </a>
                                    <a target="_blank" href={'https://bankh.github.io/'}>
                                        Professor Sinan Banks
                                    </a>
                                </div>
                            </div>
                            <div className='expItem'>

                                <ul>
                                    <li>Created a dataset containing 100,000+ 2D drawings and 3D CAD Models for training ML models by scraping web pages using selenium and Python. </li>
                                    <li>Worked under Professor Sinan Bank to research and develop generative design algorithms using TensorFlow in Python and Java.</li>
                                </ul>

                                <div style={{ display: "flex", flexDirection: "row", gap: 5, marginTop: 10 }}>
                                    <Tag name="AWS" />
                                    <Tag name="Website" />
                                    <Tag name="Leadership" />
                                </div>
                            </div>
                        </div>
                    </div>

                </section >
                <section style={{ backgroundImage: `url(${wing})`, backgroundSize: "cover", height: "600px", }}>
                    <div className={'sectionGroup'} id={'contentRoot'}>

                        <div className='expContainer'>
                            <div className='expItem'>

                                <h1><span style={{ fontWeight: 300 }}> 3. ML Intern @ </span> Wing Assistant</h1>
                                <p>After interviewing around, I started working for this NLP startup located in Berkeley, CA.</p>
                                <div className={'links'}>
                                    <a target="_blank" href={'https://wingassistant.com/'}>
                                        Company
                                    </a>
                                </div>
                            </div>

                            <div className='expItem'>

                                <ul>
                                    <li>Designed and implemented the smart reply system, a system that suggests users with replies based on
                                        conversational context by using multi level classification and the seq2seq framework under TensorFlow. </li>
                                    <li>Trained model on 220,000+ conversations from the Cornell movie corpus. </li>
                                    <li> Improved accuracy of the system from ~50% to ~80% by including scraped data from Reddit and Amazon
                                        Reviews using Selenium and Python. </li>
                                    <li> Worked with 20+ call center operators to identify where mispredictions were made in production. Added
                                        tuning training stage to prevent models from making outlier predictions within these centers. </li>
                                    <li> Eventually drafted a 5 page white paper outlining my work for future engineers to reference from</li>
                                </ul>

                                <div style={{ display: "flex", flexDirection: "row", gap: 5, marginTop: 10 }}>
                                    <Tag name="Tensorflow" />
                                    <Tag name="AWS" />
                                    <Tag name="NLP" />
                                </div>
                            </div>
                        </div>
                    </div>

                </section>
                <section style={{ backgroundImage: `url(${goodwealth})`, backgroundSize: "cover", height: "600px", }}>
                    <div className={'sectionGroup'} id={'contentRoot'}>
                        <div className='expContainer'>
                            <div className='expItem'>
                                <h1><span style={{ fontWeight: 300 }}> 4. Founding Engineer @ </span> GoodWealth</h1>
                                <p>After completing my internship at Wing, my ex-boss, the head of AI at Wing, offered me to join her at her startup as a founding engineer, and to lead the entire team.</p>

                            </div>
                            <div className='expItem'>
                                <ul>
                                    <li>Led the development of our frontend application, involving code reviewal of four engineers and two designers.</li>
                                    <li>Built out our equity/compensation insights dashboard, and negotiation platform for our consumers using Typescript, React.js, Redux and Firebase.</li>
                                    <li>Deployed the frontend application on AWS in staging and production environments, enabling a team of 8 to audit developed features and provide feedback in real time.</li>
                                </ul>

                                <div style={{ display: "flex", flexDirection: "row", gap: 5, marginTop: 10 }}>
                                    <Tag name="AWS" />
                                    <Tag name="Website" />
                                    <Tag name="Leadership" />
                                </div>
                            </div>
                        </div>
                    </div>

                </section>

                {/* <section id={'content'}>
                    <div className={'sectionGroup'} id={'contentRoot'}>
                        <h1>Relevant projects</h1>
                        <p>
                            Here's a list of some frontend projects I have worked on:
                        </p>
                        <div className="CardGroup">
                            <Card
                                title={"Cartz"}
                                link={'/projects/cartz'}
                                text={"An applicaiton I made to help my community during COVID."}
                                color="purple"
                            />
                            <Card
                                title={"College Board"}
                                link={'http://miralomacollegeboard.herokuapp.com/'}
                                text={"A High School tradition of ours was to celebrate our peers achievements by drawing out where everyone was going to college on a whiteboard. Due to COVID we were unable to do so so I created this web applicaiton over night."}
                            />
                        </div>
                    </div>

                </section> */}




                <footer style={{ height: 300, background: "black", color: "white" }}>
                    <div className={'sectionGroup'} id={'contentRoot'}>

                    </div>

                </footer>

            </div >
        );
    }
}

export default Coinbooks;
