import React from 'react';
import './card.css';

const Card = (props) => (
	<a href={props.link}>
		<span>
			<div className="Card" style={{ background: props.color }}>
				{props.Image && <img src={props.Image.default} />}
				<h3>{props.title}</h3>
				<p>{props.text}</p>
			</div>
		</span>
	</a>
);
export default Card;
